<template>
  <div class="MonitorNotifications" v-if="monitor">
    <div class="row">
      <aside class="menu col-md-2">
        <router-link class="link" :to="getRoute('email')">
          <img :src="require('@/assets/images/notifications/email.svg')" />
          <span class="ml-2">Email</span>
        </router-link>
        <router-link class="link" :to="getRoute('sms-calls')">
          <img :src="require('@/assets/images/notifications/sms-calls.svg')" />
          <span class="ml-2">SMS/Calls</span>
        </router-link>
        <router-link class="link" :to="getRoute('slack')">
          <img :src="require('@/assets/images/notifications/slack.svg')" />
          <span class="ml-2">Slack</span>
        </router-link>
        <router-link class="link" :to="getRoute('telegram')">
          <img :src="require('@/assets/images/notifications/telegram.svg')" />
          <span class="ml-2">Telegram</span>
        </router-link>
        <router-link class="link" :to="getRoute('sms')">
          <img :src="require('@/assets/images/notifications/sms.svg')" />
          <span class="ml-2">Twilio SMS</span>
        </router-link>
        <router-link class="link" :to="getRoute('voice')">
          <img :src="require('@/assets/images/notifications/voice.svg')" />
          <span class="ml-2">Twilio Voice</span>
        </router-link>
        <router-link class="link" :to="getRoute('webhook')">
          <img :src="require('@/assets/images/notifications/webhook.svg')" />
          <span class="ml-2">Webhook</span>
        </router-link>
        <router-link class="link" :to="getRoute('signl4')">
          <img :src="require('@/assets/images/notifications/signl4.png')" />
          <span class="ml-2">SIGNL4</span>
        </router-link>
        <router-link class="link" :to="getRoute('discord')">
          <img :src="require('@/assets/images/notifications/discord.svg')" />
          <span class="ml-2">Discord</span>
        </router-link>
        <router-link class="link" :to="getRoute('msteams')">
          <img :src="require('@/assets/images/notifications/microsoft-teams.svg')" />
          <span class="ml-2">Teams</span>
        </router-link>
        <router-link class="link" :to="getRoute('zapier')">
          <img :src="require('@/assets/images/notifications/zapier.png')" />
          <span class="ml-2">Zapier</span>
        </router-link>
      </aside>

      <div class="col-md-10">
        <router-view :monitor="monitor"
                     @update="loadMonitor" />
      </div>
    </div>
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'

export default {
  props: {
    monitorId: {
      required: true
    }
  },

  data () {
    return {
      monitor: null
    }
  },

  created () {
    this.loadMonitor()
  },

  methods: {
    async loadMonitor () {
      this.monitor = await monitorsApi.find(this.monitorId)
    },

    getRoute (name) {
      return {
        name: `monitors.single.notifications.${name}`,
        params: {
          id: this.monitor.id
        }
      }
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>

<style lang="scss" scoped>
  .MonitorNotifications {
    .menu {
      display: flex;
      flex-direction: column;

      .link {
        display: flex;
        align-items: center;
        padding: 10px;
        font-size: 16px;
        /*text-transform: uppercase;*/
        font-weight: 500;

        &.router-link-active img {
          filter: grayscale(0);
        }
      }

      img {
        width: 20px;
        filter: grayscale(1);
      }

      span {
        margin-left: 14px !important;
      }
    }
  }
</style>
